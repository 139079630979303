import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'

import thumb01 from '../assets/images/thumbs/01.jpg'
import thumb02 from '../assets/images/thumbs/02.jpg'
import thumb03 from '../assets/images/thumbs/03.jpg'
import thumb04 from '../assets/images/thumbs/04.jpg'
import thumb05 from '../assets/images/thumbs/05.jpg'
import thumb06 from '../assets/images/thumbs/06.jpg'

import full01 from '../assets/images/fulls/01.jpg'
import full02 from '../assets/images/fulls/02.jpg'
import full03 from '../assets/images/fulls/03.jpg'
import full04 from '../assets/images/fulls/04.jpg'
import full05 from '../assets/images/fulls/05.jpg'
import full06 from '../assets/images/fulls/06.jpg'

import fullBioPdf from '../assets/images/sharon-bio.pdf'

import axios from 'axios'

const DEFAULT_IMAGES = [
  {
    id: '1',
    src: full01,
    thumbnail: thumb01,
    caption: 'Organisational Development',
    description:
      'Improving organisational effectiveness through strategic people planning and performance management',
  },
  {
    id: '2',
    src: full02,
    thumbnail: thumb02,
    caption: 'Talent Management',
    description: 'Talent acquisition, development and retention',
  },
  {
    id: '3',
    src: full03,
    thumbnail: thumb03,
    caption: 'Culture',
    description:
      'Articulating and translating organisational values into behaviours',
  },
  {
    id: '4',
    src: full04,
    thumbnail: thumb04,
    caption: 'Learning and Development',
    description:
      'Continuous improvement, growth and enhancement of knowledge and skills',
  },
  {
    id: '5',
    src: full05,
    thumbnail: thumb05,
    caption: 'Transformation',
    description: 'Workforce diversity and change management',
  },
  {
    id: '6',
    src: full06,
    thumbnail: thumb06,
    caption: 'Compliance',
    description:
      'Adherence to labour legislation, including the Basic Conditions of Employment and Employment Equity',
  },
]

class HomeIndex extends React.Component {
  constructor() {
    super()

    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
      displayLearnMore: false,
      displayXp: false,
      name: '',
      email: '',
      message: '',
    }

    this.closeLightbox = this.closeLightbox.bind(this)
    this.gotoNext = this.gotoNext.bind(this)
    this.gotoPrevious = this.gotoPrevious.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
    this.handleClickImage = this.handleClickImage.bind(this)
    this.toggleLearnMoreDetails = this.toggleLearnMoreDetails.bind(this)
    this.toggleSeeXp = this.toggleSeeXp.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.sendMail = this.sendMail.bind(this)
  }

  openLightbox(index, event) {
    event.preventDefault()
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    })
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    })
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    })
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    })
  }
  handleClickImage() {
    if (this.state.currentImage === this.props.images.length - 1) return

    this.gotoNext()
  }

  async toggleLearnMoreDetails() {
    const displayLearnMore = this.state.displayLearnMore
    await this.setState({
      displayLearnMore: !displayLearnMore,
    })
  }

  async toggleSeeXp() {
    const displayXp = this.state.displayXp
    await this.setState({
      displayXp: !displayXp,
    })
  }

  async handleSubmit(e) {
    e.preventDefault()

    await this.sendMail()
  }

  async handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name

    await this.setState({
      [name]: value,
    })
  }

  async sendMail(userQuery) {
    const user = {
      name: this.state.name,
    }

    const response = await axios.get(' http://mail.zoho.com/api/accounts')

    alert('Response from axios:  ' + JSON.stringify(response))
  }

  render() {
    const siteTitle = 'Sharon Thomas HR Consultancy'
    const siteDescription = 'Bespoke HR Services'

    return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
        </Helmet>

        <div id="main">
          <section id="one">
            <header className="major">
              <h2>
                <strong>Sharon Thomas HR Consultancy</strong>
              </h2>

              <h3>
                <i>Bespoke HR services and solutions</i>
              </h3>
            </header>
            <p>
              Providing innovative, professional and credible HR services and
              solutions, with a wealth of HR knowledge and experience. Our HR
              services and solutions are crafted to match your unique business
              needs, to create strategic and operational interventions to
              enhance your business performance.
            </p>
            <ul className="actions">
              <li>
                <h3
                  className="button"
                  onClick={() => this.toggleLearnMoreDetails()}
                >
                  Services Offered
                </h3>
              </li>
            </ul>
            {this.state.displayLearnMore && (
              <ul id="full">
                <li>HR Strategy and Planning</li>
                <li>Talent sourcing and on-boarding</li>
                <li>Performance and talent management</li>
                <li>Leading interventions and team building facilitation</li>
                <li>Cultural and workforce transformation</li>
                <li>Consulting and advice on Industrial Relations issues</li>
              </ul>
            )}
            <h3 className="button" onClick={() => this.toggleSeeXp()}>
              Learn More about me
            </h3>
            {this.state.displayXp && (
              <p>
                An HR Professional with more than 15 years’ experience spanning
                recruitment, investment, financial services and the legal
                sectors. Experience working in Emerging Markets as an HR
                Business Partner, working with the IT and Customer Service teams
                across Africa. Strengths include relationship building,
                adaptability, solution orientation and influencing
                Qualifications include an MBA and an Honours degree in
                Organisational Psychology. Full bio available on{' '}
                <a href="https://www.linkedin.com/in/sharon-thomas-51a5a036/">
                  LinkedIn
                </a>{' '}
                or <a href={fullBioPdf}>here </a>
              </p>
            )}
          </section>

          <section id="two">
            <h2>Services offered</h2>

            <Gallery
              images={DEFAULT_IMAGES.map(
                ({ id, src, thumbnail, caption, description }) => ({
                  src,
                  thumbnail,
                  caption,
                  description,
                })
              )}
            />
            {/* 
            <ul className="actions">
              <li>
                <a href="#" className="button">
                  Full Portfolio and experience
                </a>
              </li>
            </ul> */}
          </section>

          <section id="three">
            <h2>Get In Touch</h2>
            <p>
              To explore how I can assist you with your HR requirements, please
              feel free to connect telephonically or via email, or simply send a
              message using the form below.
            </p>
            <div className="row">
              <div className="8u 12u$(small)">
                <form
                  // action="https://formspree.io/sharon@sthrc.co.za"
                  action="https://getform.io/f/1d586f80-81c9-4a9c-9689-0b4a5aecb379"
                  method="POST"
                >
                  <div className="row uniform 50%">
                    <div className="6u 12u$(xsmall)">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                        required
                        value={this.state.name}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="6u 12u$(xsmall)">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email"
                        required
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="12u">
                      <textarea
                        name="message"
                        id="message"
                        placeholder="Message"
                        rows="4"
                        required
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <ul className="actions">
                    <li>
                      <input type="submit" value="Send Message" />
                    </li>
                  </ul>
                </form>
              </div>
              <div className="4u 12u$(small)">
                <ul className="labeled-icons">
                  {/* <li>
                    <h3 className="icon fa-home">
                      <span className="label">Address</span>
                    </h3>
                    1234 Somewhere Rd.
                    <br />
                    Seapoint
                    <br />
                    Fresnaye
                  </li> */}
                  <li>
                    <h3 className="icon fa-mobile">
                      <span className="label">Phone</span>
                    </h3>
                    071-671-7686
                  </li>
                  <li>
                    <h3 className="icon fa-envelope-o">
                      <span className="label">Email</span>
                    </h3>
                    <a href="mailto: sharon@sthrc.co.za">sharon@sthrc.co.za</a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section id="four">
            <h2>Testimonials</h2>
            <hr />
            <p>
              I have worked with Sharon in her HR capacity at Old Mutual and
              would gladly recommend her to help build your culture. I enjoyed
              her engagement style and he passion for people. She embodies what
              HR is all about.
              <p />
              <i>
                Stanley Vorsatz via{' '}
                <a href="https://www.facebook.com/pg/Sharon-Thomas-HR-Consultancy-473538606508533/reviews/?post_id=10156993471986565&referrer=page_recommendations_home_card&ref=page_internal">
                  Facebook
                </a>
              </i>
            </p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
